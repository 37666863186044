footer {
  padding: 120px 0 0;
  .footer-contact {
    ul {
      list-style: none;

      li {
        &.location {
          color: white;
          text-decoration: none;
          margin: 1em 0 0;
          font-size: 16px;
          color: $lightText;
        }

        a {
          color: white;
          text-decoration: none;
          margin: 1em 0 0;
          font-size: 16px;
          color: $lightText;
          transition: color 0.32s ease;
          padding: 0 0 4px;
          &:hover {
            color: $white;
            border-bottom: 1px solid white;
          }
        }
      }
    }
  }
  .footer-legal {
    font-size: 16px;
    padding: 2em 0 0;
    color: $mid-grey;
  }
  .footer-logo {
    height: 24px;
    margin: 0 0 24px;
  }
}

.footer-numbers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  p,
  a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    color: #bbb;
  }
}
