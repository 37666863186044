.about {
  padding: 164px 0 0;

  h1 {
    font-size: clamp(32px, calc(100vw / 60), 48px);
  }

  .v-align {
    justify-content: center;
  }

  .portrait {
    img {
      border-radius: 0.25rem;
      width: 180px;
      max-width: 100%;
      height: auto;
      aspect-ratio: 4/5;

      object-fit: cover;
      margin: 0 0 2rem;
      @media (min-width: 800px) {
        width: 360px;
        margin: 0;
      }
    }
  }
  .bio {
    p {
      line-height: 1.72;
      letter-spacing: 0.02em;
      color: $lightText;
      font-size: clamp(16px, calc(100vw / 60), 20px);
    }

    &--body {
      max-width: 800px;
      margin: 0 auto;
    }

    &--header {
      margin: 0 auto;
      @media (min-width: 800px) {
        display: grid;
        grid-template-columns: 360px auto;
        gap: 4rem;
        align-items: end;
        max-width: 992px;
        margin: 0 auto 4rem;
      }
    }
  }
}

.testimonials {
  .col-6 {
    margin: 0 0 32px;
  }
}

@media (max-width: 799px) {
  .about {
    padding: 64px 0;
    .bio {
      margin: 0 auto;
      padding: 1rem 0;
    }

    .portrait {
      margin: 0 0 2rem;
    }

    .row {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 580px) {
  .about {
    .portrait {
      max-width: 200px;
    }
  }
}
