.project-details {
  padding: 164px 0 0;
  h4 {
    margin: 0 0 0.5rem;
  }

  .row {
    .col-3:last-child {
      text-align: right;
    }
  }

  .back-link {
    color: $lightText;
    text-decoration: none;
    transition: color 0.32s ease;
    position: relative;
    text-align: right;
    display: inline-block;

    &:after {
      content: "";
      width: 0;
      height: 1px;
      background: white;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: width 0.32s ease;
    }

    &:hover {
      color: white;
      &:after {
        width: 100%;
      }
    }
  }
}

.project-video {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  aspect-ratio: 16/6;
  max-height: 1200px;
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.24);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: background 0.32s ease;
  }
  &:hover {
    &:after {
      background: rgba(0, 0, 0, 0.64);
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 1;
    position: relative;
  }
}

.video-grid {
  .video-embed {
    max-width: 100%;
    opacity: 1;
    transform: none;
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 992px) {
  .project-details {
    padding: 64px 0 0;
    .row {
      display: flex;
      flex-direction: column;
      > * + * {
        margin: 2rem 0 0;
      }

      .col-3 {
        h4 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
        padding: 0 16px;
      }
      .col-3:last-child {
        text-align: left;
        font-size: 14px;
        order: -1;
        margin: 0 0 24px;
        .back-link {
          color: #777;
          &:after {
            content: none;
          }
        }
      }
    }
  }
}
