.button {
  &--contact {
    margin: 2rem 0 0;
  }
  a {
    border: 2px solid white;
    color: white;
    padding: 12px 1.5rem 10px 1.5rem;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: $bold;
    font-size: 16px;
    line-height: 1.2;
    background: rgba(0, 0, 0, 0.4);
    display: inline-block;
    transition: all 0.32s ease;

    &:hover {
      background: white;
      color: $dark;
    }
  }
}
