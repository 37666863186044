header.banner {
  height: 100vh;
  max-height: 1440px;
  min-height: 540px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    background: linear-gradient(
      0deg,
      rgba(7, 9, 15, 1) 10%,
      rgba(7, 9, 15, 0.64) 50%,
      rgba(7, 9, 15, 0.48),
      rgba(7, 9, 15, 0.32)
    );
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }

  .header-content {
    p {
      max-width: 680px;
    }
    z-index: 1;
    position: relative;
    padding: 0 0 64px;
  }

  .showreel-btn {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    border: 2px solid white;
    border-radius: 4px;
    background: $dark;
    cursor: pointer;
    position: relative;
    transition: all 0.32s ease;

    p {
      padding: 0 16px 0 0;
      z-index: 1;
      transition: all 0.32s ease;
      font-weight: $bold;
    }
    img {
      height: 24px;
      width: 24px;
      z-index: 1;
      transition: all 0.32s ease;
    }

    &:hover {
      background: white;
      p {
        color: black;
      }
      img {
        filter: invert(100);
      }
    }
  }
}

.video-bg {
  display: block;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
}

@media (max-width: 580px) {
  header.banner {
    max-height: 100%;
    min-height: 100vh;
    height: 100%;

    background-image: url("/img/will-hyde.jpg");
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    .header-content {
      z-index: 1;
      position: relative;
      padding: 80px 0 24px;
    }
  }
}

.header {
  &-clients-title {
    opacity: 0.5;
    display: inline-block;
    margin: 4rem 0 0;
  }
  &-logo-row {
    margin: 1rem 0 3rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
  }
  &-logo {
    width: auto;
    height: 20px;
    max-width: 106px;
    @media (min-width: 992px) {
      height: 26px;
      max-width: 180px;
    }

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }
}
