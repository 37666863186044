@font-face {
  font-family: "Epilogue";
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/epilogue-400.woff") format("woff");
}

@font-face {
  font-family: "Epilogue";
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/epilogue-700.woff") format("woff");
}

*,
:before,
:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.64;
  font-family: "Epilogue", Helvetica, sans-serif;
  font-size: clamp(16px, calc(100vw / 60), 18px);
  color: #f9f9f9;
  background: #07090f;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

.wrapper {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}

.flow > * + * {
  margin: 1em 0 0;
}

.flow-s > * + * {
  margin: 0.32em 0 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0;
}

.flex-col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-title {
  position: relative;
  width: 100%;
  padding: 24px;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.v-align {
  align-items: center;
}

.h-align {
  justify-content: center;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

p,
li {
  font-weight: 400;
  color: #bbb;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  font-size: clamp(32px, calc(100vw / 60), 56px);
  line-height: 1.1;
}

h2 {
  font-size: clamp(24px, calc(100vw / 60), 32px);
  line-height: 1.2;
}

h3 {
  font-size: clamp(20px, calc(100vw / 60), 24px);
  line-height: 1.3;
}

h4 {
  font-size: clamp(16px, calc(100vw / 60), 18px);
  line-height: 1.5;
}

h5 {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.24em;
  font-size: 14px;
}

h6 {
  font-size: 12px;
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.button--contact {
  margin: 2rem 0 0;
}

.button a {
  border: 2px solid white;
  color: white;
  padding: 12px 1.5rem 10px 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  background: rgba(0, 0, 0, 0.4);
  display: inline-block;
  transition: all 0.32s ease;
}

.button a:hover {
  background: white;
  color: #07090f;
}

.contact {
  min-height: calc(100vh - 580px);
}

.contact h1 {
  margin: 0 0 4rem;
}

.contact-row {
  margin: clamp(128px, calc(100vw / 4), 256px) 0 0;
}

.contact-details > * + * {
  margin: 1.5rem 0 0;
}

.contact p {
  color: #bbb;
}

.contact p.contact-location {
  color: white;
}

.contact a {
  font-weight: 700;
  transition: all 0.32s ease;
}

.contact a:hover {
  opacity: 0.7;
}

.contact-location.contact-item {
  margin: 2rem 0 0;
  padding: 2rem 0 0;
  border-top: 1px solid #444;
  display: inline-flex;
}

.contact a,
.contact-item {
  color: white;
  font-size: clamp(16px, calc(100vw / 60), 20px);
  text-decoration: none;
}

.contact-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.contact-icon {
  width: clamp(16px, calc(100vw / 60), 20px);
  height: clamp(16px, calc(100vw / 60), 20px);
}

.sandwich {
  display: none;
  cursor: pointer;
  transition: transform 0.56s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.sandwich .bread,
.sandwich .fillings {
  width: 40px;
  height: 2px;
  background: white;
  margin: 6px 0;
  border-radius: 2px;
  transition: transform 0.48s ease;
}

.sandwich.active {
  transform: rotate(180deg);
}

.sandwich.active .fillings {
  transform: scaleX(0);
}

.sandwich.active div:nth-child(1) {
  transform: rotate(45deg) translate(5.5px, 5.5px);
}

.sandwich.active div:nth-child(3) {
  transform: rotate(-45deg) translate(5.5px, -5.5px);
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  transition: background 0.32s ease;
  background-color: #07090f;
}

nav .nav-row {
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

nav .nav-row .nav-logo {
  height: 32px;
  display: inline-block;
}

nav .nav-row .nav-logo img {
  height: 100%;
  width: auto;
}

nav .nav-row .nav-menu ul li {
  list-style: none;
  padding: 0 0 0 40px;
  display: inline-block;
}

nav .nav-row .nav-menu ul li a {
  color: #f9f9f9;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.024em;
  position: relative;
  padding: 8px 0;
}

nav .nav-row .nav-menu ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background: white;
  transition: width 0.32s ease;
}

nav .nav-row .nav-menu ul li a:hover:after {
  width: 100%;
}

@keyframes activeNav {
  from {
    opacity: 0.64;
    transform: translateX(-100%) skew(8deg);
  }

  to {
    transform: none;
    opacity: 1;
  }
}

header.banner {
  height: 100vh;
  max-height: 1440px;
  min-height: 540px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
}

header.banner:after {
  content: "";
  background: linear-gradient(0deg, #07090f 10%, rgba(7, 9, 15, 0.64) 50%, rgba(7, 9, 15, 0.48), rgba(7, 9, 15, 0.32));
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

header.banner .header-content {
  z-index: 1;
  position: relative;
  padding: 0 0 64px;
}

header.banner .header-content p {
  max-width: 680px;
}

header.banner .showreel-btn {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border: 2px solid white;
  border-radius: 4px;
  background: #07090f;
  cursor: pointer;
  position: relative;
  transition: all 0.32s ease;
}

header.banner .showreel-btn p {
  padding: 0 16px 0 0;
  z-index: 1;
  transition: all 0.32s ease;
  font-weight: 700;
}

header.banner .showreel-btn img {
  height: 24px;
  width: 24px;
  z-index: 1;
  transition: all 0.32s ease;
}

header.banner .showreel-btn:hover {
  background: white;
}

header.banner .showreel-btn:hover p {
  color: black;
}

header.banner .showreel-btn:hover img {
  filter: invert(100);
}

.video-bg {
  display: block;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
}

.header-clients-title {
  opacity: 0.5;
  display: inline-block;
  margin: 4rem 0 0;
}

.header-logo-row {
  margin: 1rem 0 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
}

.header-logo {
  width: auto;
  height: 20px;
  max-width: 106px;
}

.header-logo img {
  width: auto;
  height: 100%;
  object-fit: contain;
}

footer {
  padding: 120px 0 0;
}

footer .footer-contact ul {
  list-style: none;
}

footer .footer-contact ul li.location {
  color: white;
  text-decoration: none;
  margin: 1em 0 0;
  font-size: 16px;
  color: #bbb;
}

footer .footer-contact ul li a {
  color: white;
  text-decoration: none;
  margin: 1em 0 0;
  font-size: 16px;
  color: #bbb;
  transition: color 0.32s ease;
  padding: 0 0 4px;
}

footer .footer-contact ul li a:hover {
  color: #fff;
  border-bottom: 1px solid white;
}

footer .footer-legal {
  font-size: 16px;
  padding: 2em 0 0;
  color: #666;
}

footer .footer-logo {
  height: 24px;
  margin: 0 0 24px;
}

.footer-numbers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.footer-numbers p,
.footer-numbers a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  color: #bbb;
}

.work-title h2 {
  padding: 80px 0 0;
}

.work-feed {
  margin: 0 0 64px;
}

.work-feed .work-item {
  text-decoration: none;
  color: white;
  display: inline-block;
}

.work-feed .work-item:hover img {
  transform: scale(1.16);
  filter: brightness(1.16);
}

.work-feed .work-item .work-thumb {
  width: 100%;
  border-radius: 4px;
  height: auto;
  aspect-ratio: 16/9;
  overflow: hidden;
}

.work-feed .work-item .work-thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.64s ease;
}

.work-feed .work-item .work-item-info {
  text-decoration: none;
}

.work-feed .work-item .work-item-info p {
  color: #bbb;
}

.showreel-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.94);
  z-index: 100000000;
  opacity: 0;
  justify-content: center;
  align-items: center;
}

.showreel-modal.active {
  display: flex;
  animation: videoFade 0.64s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.showreel-modal.active .video-embed {
  animation: videoTransform 0.64s 0.16s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.video-embed {
  width: 100%;
  max-width: 60vw;
  margin: 0 auto;
  transform: translateY(120px) skewY(4deg) translateZ(100px);
  opacity: 0;
  padding: 56.25% 0 0 0;
  position: relative;
  z-index: 10000000;
}

.video-embed iframe {
  z-index: 100000;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: translateZ(100px);
}

@keyframes videoFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes videoTransform {
  from {
    opacity: 0;
    transform: translateY(120px) skewY(4deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.close-btn {
  display: inline-block;
  background: white;
  color: black;
  margin: 32px 0;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  right: 50%;
  top: 0;
  transform: translateX(50%);
  position: absolute;
  cursor: pointer;
  transition: all 0.32s ease;
  z-index: 100000001;
}

.close-btn:hover {
  background: blue;
  color: white;
}

.play-btn img {
  width: 80px;
  height: 80px;
}

.about {
  padding: 164px 0 0;
}

.about h1 {
  font-size: clamp(32px, calc(100vw / 60), 48px);
}

.about .v-align {
  justify-content: center;
}

.about .portrait img {
  border-radius: 0.25rem;
  width: 180px;
  max-width: 100%;
  height: auto;
  aspect-ratio: 4/5;
  object-fit: cover;
  margin: 0 0 2rem;
}

.about .bio p {
  line-height: 1.72;
  letter-spacing: 0.02em;
  color: #bbb;
  font-size: clamp(16px, calc(100vw / 60), 20px);
}

.about .bio--body {
  max-width: 800px;
  margin: 0 auto;
}

.about .bio--header {
  margin: 0 auto;
}

.testimonials .col-6 {
  margin: 0 0 32px;
}

.project-details {
  padding: 164px 0 0;
}

.project-details h4 {
  margin: 0 0 0.5rem;
}

.project-details .row .col-3:last-child {
  text-align: right;
}

.project-details .back-link {
  color: #bbb;
  text-decoration: none;
  transition: color 0.32s ease;
  position: relative;
  text-align: right;
  display: inline-block;
}

.project-details .back-link:after {
  content: "";
  width: 0;
  height: 1px;
  background: white;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: width 0.32s ease;
}

.project-details .back-link:hover {
  color: white;
}

.project-details .back-link:hover:after {
  width: 100%;
}

.project-video {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  aspect-ratio: 16/6;
  max-height: 1200px;
  position: relative;
  cursor: pointer;
}

.project-video:after {
  content: "";
  background: rgba(0, 0, 0, 0.24);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: background 0.32s ease;
}

.project-video:hover:after {
  background: rgba(0, 0, 0, 0.64);
}

.project-video .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 1;
  position: relative;
}

.video-grid .video-embed {
  max-width: 100%;
  opacity: 1;
  transform: none;
  width: 100%;
  margin: 0;
}

.client-logos {
  margin: 200px 0 16px;
}

.client-logos .row {
  justify-content: space-between;
}

.client-logos img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 150px;
  object-fit: contain;
  margin: 0 auto;
}

.four-oh-four {
  min-height: 50vh;
  padding: 124px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
}

.four-oh-four h1 {
  line-height: 1;
}

.four-oh-four a {
  color: white;
  font-weight: 700;
}

@media (min-width: 800px) {
  .about .portrait img {
    width: 360px;
    margin: 0;
  }

  .about .bio--header {
    display: grid;
    grid-template-columns: 360px auto;
    gap: 4rem;
    align-items: end;
    max-width: 992px;
    margin: 0 auto 4rem;
  }
}

@media (min-width: 992px) {
  .header-logo {
    height: 26px;
    max-width: 180px;
  }
}

@media (max-width: 992px) {
  .wrapper {
    padding: 0 12px;
  }

  .flex-col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-title {
    padding: 12px;
    max-width: 100%;
  }

  .row {
    display: block;
  }

  .row.nav-row {
    display: flex;
  }

  nav {
    background-color: #07090f;
  }

  nav .nav-row {
    padding: 0 12px;
  }

  nav .nav-row .nav-logo {
    height: 24px;
  }

  nav .nav-row .nav-menu {
    display: none;
  }

  nav .nav-row .nav-menu.active {
    display: block;
    position: absolute;
    left: 0;
    top: 78px;
    min-height: 600px;
    height: calc(100vh - 78px);
    max-height: 100vh;
    background: #07090f;
    width: 100%;
    overflow: auto;
    animation: activeNav 0.32s ease forwards;
  }

  nav .nav-row .nav-menu.active ul li {
    display: block;
    margin: 1em 0 0;
    padding: 0;
  }

  nav .nav-row .nav-menu.active ul li a {
    padding: 8px 24px;
    width: 100%;
    display: inline-block;
    font-size: 24px;
  }

  nav .nav-row .nav-menu.active ul li a:after {
    content: none;
  }

  .sandwich {
    display: block;
  }

  .work-feed .work-item:nth-child(even) {
    transform: none;
  }

  .work-title h2 {
    padding: 32px 0 0;
  }

  .showreel-modal {
    align-items: flex-start;
  }

  .showreel-modal .video-embed {
    margin: 80px auto 0;
    max-width: 580px;
  }

  .project-details {
    padding: 64px 0 0;
  }

  .project-details .row {
    display: flex;
    flex-direction: column;
  }

  .project-details .row > * + * {
    margin: 2rem 0 0;
  }

  .project-details .row .col-3 {
    padding: 0 16px;
  }

  .project-details .row .col-3 h4 {
    font-size: 16px;
  }

  .project-details .row .col-3 p {
    font-size: 14px;
  }

  .project-details .row .col-3:last-child {
    text-align: left;
    font-size: 14px;
    order: -1;
    margin: 0 0 24px;
  }

  .project-details .row .col-3:last-child .back-link {
    color: #777;
  }

  .project-details .row .col-3:last-child .back-link:after {
    content: none;
  }

  .client-logos {
    margin: 80px 0 0;
  }

  .client-logos .row {
    display: flex;
  }

  .client-logos .row .client-logo {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0 0 64px;
  }

  .client-logos .row .client-logo:nth-last-child(2),
  .client-logos .row .client-logo:nth-last-child(1) {
    margin: 0;
  }
}

@media (max-width: 799px) {
  .about {
    padding: 64px 0;
  }

  .about .bio {
    margin: 0 auto;
    padding: 1rem 0;
  }

  .about .portrait {
    margin: 0 0 2rem;
  }

  .about .row {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 580px) {
  header.banner {
    max-height: 100%;
    min-height: 100vh;
    height: 100%;
    background-image: url("/img/will-hyde.jpg");
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
  }

  header.banner .header-content {
    z-index: 1;
    position: relative;
    padding: 80px 0 24px;
  }

  .about .portrait {
    max-width: 200px;
  }

  .client-logos {
    padding: 32px 0 0;
    margin: 0;
  }

  .client-logos .row .client-logo {
    margin: 0 0 40px;
  }

  .client-logos .row .client-logo img {
    max-width: 120px;
    max-height: 64px;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}