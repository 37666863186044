.showreel-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.94);
  z-index: 100000000;
  opacity: 0;
  justify-content: center;
  align-items: center;
  &.active {
    display: flex;
    animation: videoFade 0.64s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
    .video-embed {
      animation: videoTransform 0.64s 0.16s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
    }
  }
}

.video-embed {
  width: 100%;
  max-width: 60vw;
  margin: 0 auto;
  transform: translateY(120px) skewY(4deg) translateZ(100px);
  opacity: 0;
  padding: 56.25% 0 0 0;
  position: relative;
  z-index: 10000000;

  iframe {
    z-index: 100000;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform: translateZ(100px);
  }
}

@keyframes videoFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes videoTransform {
  from {
    opacity: 0;
    transform: translateY(120px) skewY(4deg);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.close-btn {
  display: inline-block;
  background: white;
  color: black;
  margin: 32px 0;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  right: 50%;
  top: 0;
  transform: translateX(50%);
  position: absolute;
  cursor: pointer;
  transition: all 0.32s ease;
  z-index: 100000001;
  &:hover {
    background: blue;
    color: white;
  }
}

.play-btn {
  img {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 992px) {
  .showreel-modal {
    align-items: flex-start;
    .video-embed {
      margin: 80px auto 0;
      max-width: 580px;
    }
  }
}
