.four-oh-four {
  min-height: 50vh;
  padding: 124px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  h1 {
    line-height: 1;
  }
  a {
    color: white;
    font-weight: $bold;
  }
}
