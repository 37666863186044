.sandwich {
  display: none;
  cursor: pointer;
  transition: transform 0.56s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  .bread,
  .fillings {
    width: 40px;
    height: 2px;
    background: white;
    margin: 6px 0;
    border-radius: 2px;
    transition: transform 0.48s ease;
  }
}

.sandwich.active {
  transform: rotate(180deg);
  .fillings {
    transform: scaleX(0);
  }
  div:nth-child(1) {
    transform: rotate(45deg) translate(5.5px, 5.5px);
  }
  div:nth-child(3) {
    transform: rotate(-45deg) translate(5.5px, -5.5px);
  }
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  transition: background 0.32s ease;
  background-color: $dark;

  .nav-row {
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    .nav-logo {
      height: 32px;
      display: inline-block;
      img {
        height: 100%;
        width: auto;
      }
    }
    .nav-menu {
      ul {
        li {
          list-style: none;
          padding: 0 0 0 40px;
          display: inline-block;
          a {
            color: $light;
            text-decoration: none;
            font-weight: $bold;
            letter-spacing: 0.024em;
            position: relative;
            padding: 8px 0;
            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              height: 2px;
              width: 0;
              background: white;
              transition: width 0.32s ease;
            }
            &:hover {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes activeNav {
  from {
    opacity: 0.64;
    transform: translateX(-100%) skew(8deg);
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@media (max-width: 992px) {
  nav {
    background-color: $dark;
    .nav-row {
      padding: 0 ($site-padding / 2);
      .nav-logo {
        height: 24px;
      }

      .nav-menu {
        display: none;
        &.active {
          display: block;
          position: absolute;
          left: 0;
          top: 78px;
          min-height: 600px;
          height: calc(100vh - 78px);
          max-height: 100vh;
          background: $dark;
          width: 100%;
          overflow: auto;
          animation: activeNav 0.32s ease forwards;
          ul {
            li {
              display: block;
              margin: 1em 0 0;
              padding: 0;
              a {
                padding: 8px 24px;
                width: 100%;
                display: inline-block;
                font-size: 24px;
                &:after {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .sandwich {
    display: block;
  }
}
