p,
li {
  font-weight: $regular;
  color: #bbb;
}

h1,
h2,
h3,
h4 {
  font-weight: $bold;
}

h1 {
  font-size: $h1-size;
  line-height: 1.1;
}

h2 {
  font-size: $h2-size;
  line-height: 1.2;
}

h3 {
  font-size: $h3-size;
  line-height: 1.3;
}

h4 {
  font-size: $h4-size;
  line-height: 1.5;
}

h5 {
  font-weight: $regular;
  text-transform: uppercase;
  letter-spacing: 0.24em;
  font-size: $h5-size;
}

h6 {
  font-size: $h6-size;
  font-weight: $regular;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.regular {
  font-weight: $regular;
}

.bold {
  font-weight: $bold;
}
