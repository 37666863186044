@font-face {
  font-family: "Epilogue";
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/epilogue-400.woff") format("woff");
}

@font-face {
  font-family: "Epilogue";
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/epilogue-700.woff") format("woff");
}

// Typography variables
$font: "Epilogue", Helvetica, sans-serif;

$font-size: clamp(16px, calc(100vw / 60), 18px);
$link-size: 16px;

// Font size variables
$h1-size: clamp(32px, calc(100vw / 60), 56px);
$h2-size: clamp(24px, calc(100vw / 60), 32px);
$h3-size: clamp(20px, calc(100vw / 60), 24px);
$h4-size: clamp(16px, calc(100vw / 60), 18px);
$h5-size: 14px;
$h6-size: 12px;

$regular: 400;
$bold: 700;
