.contact {
  min-height: calc(100vh - 580px);
  h1 {
    margin: 0 0 4rem;
  }

  &-row {
    margin: clamp(128px, calc(100vw / 4), 256px) 0 0;
  }

  &-details {
    > * + * {
      margin: 1.5rem 0 0;
    }
  }

  p {
    color: #bbb;
    &.contact-location {
      color: white;
    }
  }

  a {
    font-weight: $bold;
    transition: all 0.32s ease;
    &:hover {
      opacity: 0.7;
    }
  }

  &-location.contact-item {
    margin: 2rem 0 0;
    padding: 2rem 0 0;
    border-top: 1px solid #444;
    display: inline-flex;
  }

  a,
  &-item {
    color: white;
    font-size: clamp(16px, calc(100vw / 60), 20px);
    text-decoration: none;
  }
  &-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }

  &-icon {
    width: clamp(16px, calc(100vw / 60), 20px);
    height: clamp(16px, calc(100vw / 60), 20px);
  }
}
