.client-logos {
  margin: 200px 0 16px;
  .row {
    justify-content: space-between;
  }
  img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 150px;
    object-fit: contain;
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .client-logos {
    margin: 80px 0 0;
    .row {
      display: flex;
      .client-logo {
        flex: 0 0 50%;
        max-width: 50%;
        margin: 0 0 64px;
        &:nth-last-child(2),
        &:nth-last-child(1) {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .client-logos {
    padding: 32px 0 0;
    margin: 0;
    .row {
      .client-logo {
        margin: 0 0 40px;
        img {
          max-width: 120px;
          max-height: 64px;
        }
      }
    }
  }
}
