.work-title {
  h2 {
    padding: 80px 0 0;
  }
}

.work-feed {
  margin: 0 0 64px;

  .work-item {
    text-decoration: none;
    color: white;
    display: inline-block;

    &:hover {
      img {
        transform: scale(1.16);
        filter: brightness(1.16);
      }
    }

    .work-thumb {
      width: 100%;
      border-radius: 4px;
      height: auto;
      aspect-ratio: 16/9;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.64s ease;
      }
    }

    .work-item-info {
      text-decoration: none;

      p {
        color: $lightText;
      }
    }
  }
}

@media (max-width: 992px) {
  .work-feed {
    .work-item {
      &:nth-child(even) {
        transform: none;
      }
    }
  }
  .work-title {
    h2 {
      padding: 32px 0 0;
    }
  }
}
